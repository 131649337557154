import React from 'react'
import moment from 'moment'
import theme from '../helpers/theme'
import Layout from '../components/layout'
import EventThumbnail from '../components/event-thumbnail'
import SEO from '../components/seo'

const EventsIndexTemplate = (props) => {

  const settings = props.data.settings.edges[0].node

  // MICROCOPY
  //
  const {
    upcomingEvents,
    pastEvents
  } = settings

  const now = parseInt(moment().format('YYYYMMDD'))

  const current = props.data.events && props.data.events.edges.filter(({node}) => {
    const end = node.end || node.start
    const date = parseInt(moment(end).format('YYYYMMDD'))
    return now <= date
  })

  const past = props.data.events && props.data.events.edges.filter(({node}) => {
    const end = node.end || node.start
    const date = parseInt(moment(end).format('YYYYMMDD'))
    return now > date
  })

  const pastSorted = past.sort((a, b) => {
    const endA = a.node.end || a.node.start
    const endB = b.node.end || b.node.start
    const dateA = parseInt(moment(endA).format('YYYYMMDD'))
    const dateB = parseInt(moment(endB).format('YYYYMMDD'))
    return dateB - dateA
  })

  return (
    <Layout image={settings.navImageEvents} theme={theme.green} settings={settings} location={props.location}>
      <SEO title={'Events Calendar'} />

      {/* <section className="innerx2">

        <h1 className="adjust guttersx2">{upcomingEvents}</h1>

        <div className="outerx2 guttersx2">
          {current &&
            current.map(({node}, i) => {
              const end = node.end || node.start
              const date = parseInt(moment(end).format('YYYYMMDD'))
              return now <= date ? (
                <EventThumbnail {...node} theme={theme.green} key={`${node.slug}-event-link`} />
              ) : null
            })}
        </div>

      </section> */}

			<section className="border-top">

				<h2 className="adjust guttersx2 innerx2">{upcomingEvents}</h2>

				<div className="border-top">
					{current &&
						current.map(({node}, i) => {
							const end = node.end || node.start
							const date = parseInt(moment(end).format('YYYYMMDD'))
							return now <= date ? (
								<EventThumbnail {...node} theme={theme.green} key={`${node.slug}-event-link`} />
							) : null
						}
					)}
				</div>

			</section>

      {pastSorted && pastSorted.length > 0 ? (
        <section className="mtx2">

          <h2 className="adjust guttersx2">{pastEvents}</h2>

          <div className="mtx2 border-top">
            {pastSorted &&
              pastSorted.map(({node}, i) => {
                const end = node.end || node.start
                const date = parseInt(moment(end).format('YYYYMMDD'))
                return now > date ? (
                  <EventThumbnail {...node} past theme={theme.green} key={`${node.slug}-event-link`} />
                ) : null
              })}
          </div>

        </section>
      ) : null}

    </Layout>
  )
}

export default EventsIndexTemplate
